import {
  ChangeEvent,
  useMemo,
  Suspense,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Spinner,
} from 'react-bootstrap';
import {
  PDPData,
  HousingTypeData,
  EmploymentTypeData,
  CommunityTypeData,
} from '../types/pdp.types';
import {
  MIN_SALARY,
  MAX_SALARY,
  GAP_SALARY,
} from '../types/constants';
import SanitizeHTML from '../services/html.service';
import {
  pdpStepState,
  pdpState,
  housingTypeOptionsState,
  employmentTypeOptionsState,
  communityTypesState,
} from '../services/state.service';
import PreferencesTable from './PreferencesTable';
import { generateSalaryTooltipContent, tryParseInt } from '../utils';
import { useStepValid } from '../hooks/useStepValid';
import HelpPopup from './generic/HelpPopup';

function Preferences(): JSX.Element {
  const { t } = useTranslation();
  const [pdp, setPDP] = useRecoilState<PDPData>(pdpState);
  const housingTypes = useRecoilValue<HousingTypeData[]>(housingTypeOptionsState);
  const employmentTypes = useRecoilValue<EmploymentTypeData[]>(employmentTypeOptionsState);
  const communityTypeOptions = useRecoilValue<CommunityTypeData[]>(communityTypesState);

  const requiredFields = useMemo(() => (
    [
      'employmentType',
      'positionTypes',
      'minimumExpectedSalary',
      'housingTypes',
      'interculturalMinistries',
      'advancedSearch',
      'matchLanguage',
      'communityTypes',
    ]
  ), []);
  useStepValid('preferences', pdp, requiredFields, pdpStepState);

  const housings = housingTypes.map((option: any) => (
    <div key={option.id} className="ms-2">
      <Form.Check
        checked={pdp.housingTypes.includes(option.id)}
        type="checkbox"
        id={option.id}
        label={option.housingType}
        className="genericbutton"
        name="pdpcheckbox"
        onChange={
          !pdp.housingTypes.includes(option.id)
            ? () => setPDP((p: any) => ({
              ...p, housingTypes: [...pdp.housingTypes, option.id],
            }))
            : () => setPDP((p: any) => ({
              ...p, housingTypes: pdp.housingTypes.filter((item: any) => item !== option.id),
            }))
        }
      />
    </div>
  ));

  const communityTypeCheckboxes = communityTypeOptions.map((option: CommunityTypeData) => (
    <Form.Check
      type="checkbox"
      checked={pdp.communityTypes?.includes(parseInt(option.id.toString(), 10))}
      id={`communityTypes-option-${option.id}`}
      key={`communityTypes-option-${option.id}`}
      label={option.description}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        pdp.communityTypes?.includes(parseInt(option.id.toString(), 10))
          ? setPDP((p: any) => ({
            ...p,
            communityTypes: pdp.communityTypes.filter((item: any) => item !== option.id),
          }))
          : setPDP((p: any) => ({
            ...p,
            communityTypes: [...pdp.communityTypes, option.id],
          }))
      )}
    />
  ));

  return (
    <div className="col-lg-8 col-12 mx-auto">
      <div className="mx-auto">
        <div className="title">
          {t('PDP.PDP_Title')}
        </div>
        <div className="mb-3">
          <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        </div>
        <div className="mb-3">
          <Form.Group
            className="mb-4 fit-content"
            controlId="formEmploymentTypeSelect"
          >
            <Form.Label className="bodyheader required">
              {t('PDP.Employment_Type')}
            </Form.Label>
            <Suspense fallback={
              (
                <Spinner
                  animation="border"
                  size="sm"
                />
              )
            }
            >
              <Form.Select
                value={pdp.employmentType?.id || ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setPDP((p: any) => ({
                  ...p,
                  employmentType: employmentTypes.find((et) => (
                    parseInt(et.id, 10) === parseInt(e.target.value, 10)
                  )),
                }))}
              >
                <option key="" value="">
                  {t('PDP.Desired_Employment')}
                </option>
                {(employmentTypes?.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.description}
                  </option>
                )))}
              </Form.Select>
            </Suspense>
          </Form.Group>
        </div>

        <div className="mb-4">
          <div className="bodyheader required">
            {t('PDP.Position_Type_Title')}
          </div>
          <div className="mb-3">
            {t('PDP.Position_Type_Description')}
          </div>
          <div className="border border-bottom-0 rounded">
            <Suspense fallback={
              (
                <Spinner
                  animation="border"
                  size="sm"
                />
              )
            }
            >
              <PreferencesTable />
            </Suspense>
          </div>
        </div>

        <div className="text-start col-4">
          <Form.Label className="bodyheader required">
            {t('PDP.Min_Expected_Salary')}
          </Form.Label>
          <HelpPopup
            helpKey="tooltip-salary-definition"
            placement="right"
            trigger="click"
            content={generateSalaryTooltipContent(t('Effective_Salary_Definition'))}
          />
          <Form.Label className="bodyheader">
            {`$${pdp.minimumExpectedSalary}`}
          </Form.Label>
          <Form.Range
            value={pdp.minimumExpectedSalary}
            min={MIN_SALARY}
            max={MAX_SALARY}
            step={GAP_SALARY}
            onChange={(e: any) => setPDP((p: any) => ({
              ...p, minimumExpectedSalary: tryParseInt(e.target.value),
            }))}
          />
        </div>
      </div>

      <Form.Group className="mb-4">
        <Form.Label className="bodyheader required">
          {t('PDP.Housing_Types_Header')}
        </Form.Label>
        <div>
          {t('PDP.Housing_Types_Description')}
        </div>
        <Suspense fallback={
          (
            <Spinner
              animation="border"
              size="sm"
            />
          )
        }
        >
          {housings}
        </Suspense>
      </Form.Group>
      <div className="mb-3">
        <Form.Group className="mb-4">
          <Form.Label className="bodyheader required">
            {t('PDP.Intercultural_Ministries')}
          </Form.Label>
          <Form.Check
            defaultChecked={pdp.interculturalMinistries}
            type="radio"
            id="intercultural-ministries-1"
            label={t('Yes')}
            name="InterculturalRadio"
            className="preferencesradio"
            onChange={() => setPDP((p: any) => ({
              ...p, interculturalMinistries: true,
            }))}
          />
          <Form.Check
            defaultChecked={(pdp.interculturalMinistries === false) ? !pdp.interculturalMinistries : false}
            type="radio"
            label={t('No')}
            id="intercultural-ministries-2"
            name="InterculturalRadio"
            className="preferencesradio"
            onChange={() => setPDP((p: any) => ({
              ...p, interculturalMinistries: false,
            }))}
          />
        </Form.Group>
      </div>
      <div className="mb-3">
        <Form.Group className="mb-4">
          <Form.Label className="bodyheader required">
            {t('PDP.Advanced_Searches')}
          </Form.Label>
          <Form.Check
            defaultChecked={pdp.advancedSearch}
            type="radio"
            id="advanced-searches-1"
            label={t('Yes')}
            name="AdvancedRadio"
            className="preferencesradio"
            onChange={() => setPDP((p: any) => ({
              ...p, advancedSearch: true,
            }))}
          />
          <Form.Check
            defaultChecked={(pdp.advancedSearch === false) ? !pdp.advancedSearch : false}
            type="radio"
            label={t('No')}
            id="advanced-searches-2"
            name="AdvancedRadio"
            className="preferencesradio"
            onChange={() => setPDP((p: any) => ({
              ...p, advancedSearch: false,
            }))}
          />
        </Form.Group>
      </div>
      <div className="mb-3">
        <Form.Group
          className="mb-4"
          controlId="formMatchLanguageSelect"
        >
          <Form.Label className="bodyheader required">
            {t('Match_Language')}
          </Form.Label>
          <Form.Select
            className="fit-content"
            value={pdp.matchLanguage || 'en'}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setPDP((p: any) => ({
              ...p,
              matchLanguage: e.target.value,
            }))}
          >
            <option key="en" value="en">
              {t('en')}
            </option>
            <option key="es" value="es">
              {t('es')}
            </option>
            <option key="ko" value="ko">
              {t('ko')}
            </option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className="mb-3">
        <Form.Group>
          <Form.Label
            className="bodyheader required"
          >
            {t('Community_Type_Preferences')}
          </Form.Label>
          <div className="border rounded community-type-checkboxes">
            {communityTypeCheckboxes}
          </div>
        </Form.Group>
      </div>
      <div className="mb-3">
        <Form.Group className="mb-4">
          <Form.Check
            defaultChecked={pdp.noMatchingWithinPresbytery}
            type="checkbox"
            id="no-matching-1"
            label={t('No_Matching_Within_Presbytery')}
            onChange={() => setPDP((p: any) => ({
              ...p, noMatchingWithinPresbytery: !p.noMatchingWithinPresbytery,
            }))}
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default Preferences;
