import {
  useEffect,
  useMemo,
  useState,
  Suspense,
} from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import PDPService from '../services/pdp.service';
import {
  PDPData,
  IPositionType,
  IPositionTypeResponse,
  IExperienceLevel,
  ServiceData,
  ServiceResponseData,
  WorkData,
  WorkResponseData,
  PDPNarrativeData,
  PDPNarrativesResponseData,
  GeographicalOptionData,
  GeographicalOptionResponseData,
  GeographicalOptionDefaultData,
  PresbyteryData,
} from '../types/pdp.types';
import {
  CallingInfoResponseData,
} from '../types/profile.types';
import api from '../services/api.service';
import {
  pdpStepState,
  pdpState,
  presbyteryOptionsState,
  statesListOptionsState,
  experienceLevelOptionsState,
} from '../services/state.service';
import { StepProps, SelectOption } from '../types/generic.types';
import { useStepValid } from '../hooks/useStepValid';
import SuspenseLoading from './generic/SuspenseLoading';
import SanitizeHTML from '../services/html.service';
import { FormatLocalDate } from '../utils';
import CommunityTypeOptions from './pdp/CommunityTypeOptions';

interface OptionalLinkData {
  id: number,
  pdp: number,
  linkTitle: string,
  linkDescription: string,
  linkUrl: string,
}

interface OptionalLinkResponseData {
  data: OptionalLinkData[],
}

interface ReferenceData {
  id: number,
  pdp: number,
  name: string,
  relation: string,
  phone: string,
  email: string,
}

interface ReferenceResponseData {
  data: ReferenceData[],
}

function Review({
  setActiveStep,
  steps,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const pdp = useRecoilValue<PDPData>(pdpState);
  const [positionTypeList, setPositionTypeList] = useState<IPositionType[]>([]);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>();
  const [serviceData, setServiceData] = useState<ServiceData[]>([]);
  const [allWorkData, setAllWorkData] = useState<WorkData[]>([]);
  const [optionalLink, setOptionalLink] = useState<OptionalLinkData[]>([]);
  const [reference, setReference] = useState<ReferenceData[]>([]);
  const [PDPNarratives, setPDPNarratives] = useState<PDPNarrativeData[]>([]);
  const [geoOptions, setGeoOptions] = useState<GeographicalOptionData>(GeographicalOptionDefaultData);
  const presbyteryOptions = useRecoilValue<PresbyteryData[]>(presbyteryOptionsState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);

  const requiredFields = useMemo(() => [], []);
  useStepValid('review', pdp, requiredFields, pdpStepState);

  useEffect(() => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'No Experience/First Ordained Call'), 1);
    setFilteredExpLevels(list);

    api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
      if (response.data[0].eccStatusValue === 'candidate') {
        setFilteredExpLevels(list.filter((p) => p.value === 'First Ordained Call'));
      }
    });
  }, [experienceLevels]);

  useEffect(() => {
    PDPService.GetPositionTypes(pdp.id.toString()).then(
      (response: IPositionTypeResponse) => (setPositionTypeList(response.data.options)),
    );

    PDPService.GetService(pdp.id, false).then(
      (response: ServiceResponseData) => (setServiceData(response.data)),
    );

    PDPService.GetWork(pdp.id, false).then(
      (response: WorkResponseData) => (setAllWorkData(response.data)),
    );

    api.get(`/pdp/${pdp.id}/narrative/`).then(
      (response: PDPNarrativesResponseData) => {
        const sortedData = response.data.sort((a, b) => a.displayOrder - b.displayOrder);
        setPDPNarratives(sortedData);
      },
    );

    api.get(`/pdp/${pdp.id}/optionallinks/`).then(
      (response: OptionalLinkResponseData) => (setOptionalLink(response.data)),
    );

    api.get(`/pdp/${pdp.id}/reference/`).then(
      (response: ReferenceResponseData) => (setReference(response.data)),
    );

    api.get(`/pdp/${pdp.id}/geographicaloptions/`).then(
      (response: GeographicalOptionResponseData) => (setGeoOptions(response.data[0])),
    );
  }, [pdp.id]);

  return (
    <div className="col-lg-8 col-12 mx-auto">
      <div className="mx-auto col-12">
        <div className="title">
          {t('PDP.Review_Your')}
        </div>
        <div className="mb-4">
          {t('PDP.Review_Section')}
        </div>

        {/* Preferences */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">
              {t('PDP.Preferences')}
            </div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[0])}
              data-step-key="preferences"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Employment_Type')}
                </div>
                {pdp.employmentType?.description}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Position_Type_Title')}
                </div>
                <Suspense fallback={<SuspenseLoading />}>
                  <div>
                    {pdp.positionTypes.map((option: any) => (
                      <div key={`postype-${option.id}`}>
                        {positionTypeList.map((item: any) => (
                          (item.id === option.positionType)
                            ? (
                              <div key={`postype-item-${item.id}`}>
                                {item.positionType}
                              </div>
                            )
                            : null))}
                        {filteredExpLevels?.map((level: any) => (
                          (level.id === option.experienceLevel)
                            ? (
                              <div key={`explevel-${level.id}`}>
                                {level.displayName}
                              </div>
                            )
                            : (
                              null
                            )
                        ))}
                      </div>
                    ))}
                  </div>
                </Suspense>
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Min_Expected_Salary')}
                </div>
                {pdp.minimumExpectedSalary}
              </div>
              <CommunityTypeOptions communityTypes={pdp.communityTypes} />
            </div>
          </div>
        </div>

        {/* Geographical Options */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">
              {t('PDP.Geographical_Options')}
            </div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[1])}
              data-step-key="preferencesgeographicaloptions"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="my-4 mx-3">
            <div className="row">
              <div className="col-6">
                <div className="mb-2 reviewtitle">{geoOptions.geoLocation}</div>
                <div>
                  {geoOptions.searchByState.map((option: any) => (
                    <div key={`search-by-state-${option}`}>
                      {statesListOptions.map((item: any) => (
                        (item.id === option)
                          ? (
                            <div key={`state-option-${item.id}`}>
                              {item.name}
                            </div>
                          ) : (
                            null
                          )
                      ))}
                    </div>
                  ))}
                </div>
                <div>
                  {geoOptions.searchByPresbytery.map((option: any) => (
                    <div key={`search-by-presbytery-${option}`}>
                      {presbyteryOptions.map((item: any) => (
                        (item.number === option)
                          ? (
                            <div key={`presbytery-option-${item.number}`}>
                              {item.name}
                            </div>
                          ) : (
                            null
                          )
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Work Experience */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">
              {t('PDP.Work_Experience')}
            </div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[2])}
              data-step-key="workexperience"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4 mx-3">
                {allWorkData.map((work: WorkData) => (
                  <div key={`workexp-${work.id}`} className="text-start my-4">
                    <b>{FormatLocalDate(work.start)}</b>
                    &nbsp;-&nbsp;
                    <b>{work.end ? FormatLocalDate(work.end) : t('Present')}</b>
                    <br />
                    {work.organization}
                    &nbsp;&nbsp;&nbsp;
                    {work.position}
                    <br />
                    {work.name}
                    <br />
                    {work.city}
                    ,&nbsp;
                    {work.state}
                    <br />
                    {work.community && work.size && (
                      <>
                        (
                        {work.community}
                        ,&nbsp;
                        {work.size}
                        )
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Service to the Church */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">
              {t('PDP.Service_to_the_Church')}
            </div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[3])}
              data-step-key="servicetothechurch"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-12">
              <div className="my-4  mx-3">
                {serviceData.map((option: any) => (
                  <div key={`servicetochurch-${option.id}`} className="text-start my-4 mx-3">
                    <b>{FormatLocalDate(option.start)}</b>
                    &nbsp;-&nbsp;
                    <b>{option.end ? FormatLocalDate(option.end) : t('Present')}</b>
                    <br />
                    {option.position}
                    <br />
                    {option.organization}
                    {option.congregationname?.length > 0 && (
                      <>
                        <br />
                        {option.congregationname}
                        ,&nbsp;
                      </>
                    )}
                    {option.presbyteryname}
                    {option.description?.length > 0 && (
                      <>
                        <br />
                        <SanitizeHTML html={option.description || ''} />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Narratives */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">{t('PDP.Narratives')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[4])}
              data-step-key="narratives"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              <>{t('PDP.Edit')}</>
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-11 mt-4 ms-auto me-5">
              {PDPNarratives.map((option: PDPNarrativeData) => (
                <div key={option.id} className="border border-dark rounded-3 mb-3">
                  <Table className="pdp-narrative-panel">
                    <thead>
                      <tr className="linktableheader">
                        <td>{option.question}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="text-center fw-bold">
                            {t('Applies_To_Position')}
                            &nbsp;
                            {option.positionTypes}
                          </div>
                          <hr />
                          <div className="mb-1">
                            <span>Response: </span>
                            <SanitizeHTML html={option.response || ''} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Optional Links */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">{t('PDP.Optional_Links')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[5])}
              data-step-key="optionallinks"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4  mx-3">
                {optionalLink.map((option: any) => (
                  <div key={`optionallinks-${option.id}`} className="text-start my-4 mx-3">
                    <b>{option.linkTitle}</b>
                    &nbsp;-&nbsp;
                    {option.linkDescription}
                    &nbsp;-&nbsp;
                    <a
                      href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {option.linkUrl}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* References */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-2 mb-2 ms-3 fw-bold h4">{t('PDP.References')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[6])}
              data-step-key="references"
              className="col-2 my-2 ms-2 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4  mx-3">
                {reference.map((option: any, index: any) => (
                  <div key={`references-${option.id}`}>
                    <div className="mx-3 mb-2 reviewtitle">
                      {t('PDP.Singular_Reference')}
                      &nbsp;#
                      {index + 1}
                    </div>
                    <div className="text-start my-3 mx-3">
                      <b>{option.name}</b>
                      <br />
                      {option.relation}
                      <br />
                      {option.phone}
                      <br />
                      {option.email}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
