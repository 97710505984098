import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphData } from '../../types/reports';
import { GRAPH_COLORS } from '../../types/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarGraph({ graphData, title }: { graphData: GraphData, title: string }) {
  const [data, setData] = useState<GraphData>(graphData);
  const { t } = useTranslation();

  useEffect(() => {
    const graphDataCopy = { ...graphData, labels: graphData.labels.map((label) => t(label)) };
    graphDataCopy.datasets = graphDataCopy.datasets.map((dataset, index) => ({
      ...dataset,
      backgroundColor: GRAPH_COLORS[index],
      borderColor: GRAPH_COLORS[index],
    }));
    setData(graphDataCopy);
  }, [graphData, title]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: {
            size: 14, // Adjust font size for legend
          },
        },
      },
      datalabels: {
        display: false, // Disable data labels
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove vertical grid lines
        },
        ticks: {
          font: {
            size: 14, // Adjust font size for x-axis
          },
        },
      },
      y: {
        ticks: {
          stepSize: 100, // Increment the y-axis by 200
          font: {
            size: 14, // Adjust font size for y-axis
          },
        },
      },
    },
    datasets: {
      bar: {
        maxBarThickness: 20, // Maximum width
        categoryPercentage: 0.7, // Space between groups (0-1)
        barPercentage: 1, // Space between bars (0-1)
      },
    },
  };

  return (
    <div className="page-break-inside-avoid mb-4">
      <h5 className="text-center">{title}</h5>
      <div style={{ height: '400px', width: '100%' }}>
        <Bar
          data={data}
          options={options}
        />
      </div>
    </div>
  );
}

export default BarGraph;
