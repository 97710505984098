import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { useMemo } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { PieGraphData } from '../../types/reports';
import { GRAPH_COLORS } from '../../types/constants';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface PieGraphProps {
  graphData: PieGraphData;
  title: string;
}

const getColorMappedData = (graphData: PieGraphData): PieGraphData => ({
  ...graphData,
  datasets: graphData.datasets.map((dataset) => ({
    ...dataset,
    backgroundColor: graphData.labels.map((_, i) => GRAPH_COLORS[i % GRAPH_COLORS.length]),
    borderColor: graphData.labels.map((_, i) => GRAPH_COLORS[i % GRAPH_COLORS.length]),
  })),
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  radius: '75%',
  plugins: {
    legend: {
      position: 'bottom' as const, // Position the legend to the right
      labels: {
        font: {
          size: 14, // Adjust font size for legend
        },
      },
      align: 'center' as const,
    },
    datalabels: {
      display: true,
      formatter: (value: any, context: any) => {
        const dataset = context.chart.data.datasets[0];
        const total = dataset.data.reduce((acc: any, val: any) => acc + val, 0);
        const percentage = ((value / total) * 100).toFixed(2);
        let label = context.chart.data.labels[context.dataIndex];
        if (label.length > 7) {
          label = `${label.slice(0, 7)}...`;
        }
        if ((value / total) > 0.03) {
          return `${label}\n(${percentage}%)`;
        }
        return '';
      },
      align: 'end' as const,
      color: '#000000',
      font: {
        size: 12, // Reduce the text size
        color: '#000000',
      },
      offset: 60,
    },
  },
};

function PieGraph({
  graphData,
  title,
}: PieGraphProps) {
  const data = useMemo(() => getColorMappedData(graphData), [graphData]);

  return (
    <div className="page-break-inside-avoid">
      <h5 className="text-center">{title}</h5>
      <div className="chart-print-size">
        <Pie
          data={data}
          options={options}
          height={370}
          width={370}
        />
      </div>
    </div>
  );
}

export default PieGraph;
