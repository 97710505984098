import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Button,
  Spinner,
  Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaLongArrowAltLeft, FaFilter } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { userProfileState, statesListOptionsState } from '../services/state.service';
import { UserProfile } from '../types/user.types';
import api from '../services/api.service';
import BarGraph from '../components/generic/BarGraph';
import PieGraph from '../components/generic/PieGraph';
import PositionsAvailableTable from '../components/reports/PositionsAvailableTable';
import { SelectOption } from '../types/generic.types';
import {
  ApplicationsPositionsResponse,
  ApplicantsPositionsState,
  ApplicantsPositionsDefaultState,
  GraphData,
  GraphDataDefaultData,
  PieGraphData,
  PieGraphDataDefaultData,
  CommunityTypeDistributionData,
  CommunityTypeDistributionDefaultData,
  CLCReportFilterData,
  CLCReportFilterDefaultData,
} from '../types/reports';
import CLCReportsFilterModal from '../components/CLCReportsFilterModal';
import ActivePDPsTable from '../components/reports/ActivePDPsTable';

function CLCReports() {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const [data, setData] = useState<ApplicantsPositionsState>(ApplicantsPositionsDefaultState);
  const [genderData, setGenderData] = useState<GraphData>(GraphDataDefaultData);
  const [raceData, setRaceData] = useState<GraphData>(GraphDataDefaultData);
  const [eccData, setEccData] = useState<CommunityTypeDistributionData>(CommunityTypeDistributionDefaultData);
  const [activeRacePDPData, setActiveRacePDPData] = useState<PieGraphData>(PieGraphDataDefaultData);
  const [activeGenderPDPData, setActiveGenderPDPData] = useState<PieGraphData>(PieGraphDataDefaultData);
  const [showReportFilterModal, setShowReportFilterModal] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<boolean>(false);
  const [positionsTableFilter, setPositionsTableFilter] = useState<CLCReportFilterData>(CLCReportFilterDefaultData);
  const [currentState, setCurrentState] = useState<string>('');
  const [eccStateFilter, setEccStateFilter] = useState<any>({ eccState: '' });
  const [loadingPositions, setLoadingPositions] = useState(true);
  const [loadingEccActivePDPs, setLoadingEccActivePDPs] = useState(true);

  useEffect(() => {
    if (userProfile.isLoggedIn) {
      const queryParams = new URLSearchParams();
      let hasFilters = false;

      const appendFilter = (filterKey: string, filterValue: number[] | string) => {
        if (Array.isArray(filterValue) && filterValue.length > 0) {
          queryParams.append(filterKey, filterValue.join(','));
          hasFilters = true;
          setLoadingPositions(true);
        } else if (filterValue.length === 0) {
          setLoadingPositions(true);
        } else if (typeof filterValue === 'string' && filterValue) {
          queryParams.append(filterKey, filterValue);
          setLoadingPositions(true);
        }
      };

      appendFilter('communityType', positionsTableFilter.communityType);
      appendFilter('eccStatus', positionsTableFilter.eccStatus);
      appendFilter('employmentType', positionsTableFilter.employmentType);
      appendFilter('expLevel', positionsTableFilter.experienceLevel);
      appendFilter('state', positionsTableFilter.state);

      api.get(`applicantspositions/?${queryParams.toString()}`).then((response: ApplicationsPositionsResponse) => {
        setData(response.data);
        setGenderData(response.data.eccGenderDistribution.graphData);
        setRaceData(response.data.eccRaceDistribution.graphData);
        setActiveRacePDPData(response.data.pdpActiveRaceDistribution.graphData);
        setActiveGenderPDPData(response.data.pdpActiveGenderDistribution.graphData);
      }).catch(() => {}).finally(() => { setLoadingPositions(false); });
      setAppliedFilters(hasFilters);
    }
    setShowReportFilterModal(false);
  }, [userProfile, positionsTableFilter]);

  useEffect(() => {
    const queryParams = new URLSearchParams();
    if (eccStateFilter.eccState) {
      queryParams.append('eccState', eccStateFilter.eccState);
      setLoadingEccActivePDPs(true);
    }
    api.get(`applicantspositions/?${queryParams.toString()}`).then((response: ApplicationsPositionsResponse) => {
      setEccData(response.data.eccActivePDPDistribution);
    }).catch(() => {}).finally(() => { setLoadingEccActivePDPs(false); });
  }, [eccStateFilter.eccState]);

  const setTableFilters = useCallback((result: boolean, filter: CLCReportFilterData): void => {
    if (result) {
      setPositionsTableFilter(
        {
          communityType: filter.communityType,
          experienceLevel: filter.experienceLevel,
          employmentType: filter.employmentType,
          eccStatus: filter.eccStatus,
          state: filter.state,
        },
      );
    }
    setShowReportFilterModal(false);
  }, []);

  return (
    <Container fluid className="mt-3">
      <CLCReportsFilterModal
        show={showReportFilterModal}
        state={currentState}
        hide={() => setShowReportFilterModal(false)}
        callback={setTableFilters}
      />
      <Link to="/" className="dashboard-link">
        <FaLongArrowAltLeft />
        <span>{t('Back_to_Dashboard')}</span>
      </Link>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <h1 className="clc-report-title">{t('CLC_Report_Center')}</h1>
          <div className="box mx-auto">
            <div>{`${t('Total_Active_MDPs')}: ${data.summary.positions}`}</div>
            <div>{`${t('Total_Active_PDPs')}: ${data.summary.seekers}`}</div>
            <div>{`${t('Intercultural_Congregations')}: ${data.summary.intercultural}`}</div>
            <div>{`${t('English_Required_MDPs')}: ${data.summary.englishPositions}`}</div>
            <div>{`${t('Spanish_Required_MDPs')}: ${data.summary.spanishPositions}`}</div>
            <div>{`${t('Korean_Required_MDPs')}: ${data.summary.otherPositions}`}</div>
            <div>{`${t('Other_Language_Required')}: ${data.summary.otherPositions}`}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-print-center">
          <BarGraph title={t('Active_PDP_ECC_Status_By_Gender')} graphData={genderData} />
        </div>
        <div className="col-sm-12 col-md-6 col-print-center">
          <BarGraph title={t('Active_PDP_ECC_Status_By_Race')} graphData={raceData} />
        </div>
        <div className="col-sm-10 col-md-5 col-lg-4 mx-auto mb-3 col-print-center">
          <PieGraph
            title={t('Active_PDP_By_Race')}
            graphData={activeRacePDPData}
          />
        </div>
        <div className="col-xs-10 col-md-5 col-lg-4 mx-auto mb-3 col-print-center">
          <PieGraph
            title={t('Active_PDP_By_Gender')}
            graphData={activeGenderPDPData}
          />
        </div>
        <div>
          <div className="col-sm-12 col-md-8 mx-auto positions-table">
            <h1 className="clc-report-title mt-3">{t('Positions_Available')}</h1>
            <div className="row">
              <div className="my-2 d-flex justify-content-end">
                <Form.Select
                  className="pill-select btn btn-primary custom-select"
                  defaultValue={positionsTableFilter.state}
                  onChange={(e) => {
                    const state = statesListOptions.find((p) => p.id.toString() === e.target.value);
                    if (state) {
                      setPositionsTableFilter({ ...positionsTableFilter, state: state.value });
                      setCurrentState(state.value);
                    }
                    if (e.target.value === '-1') {
                      setPositionsTableFilter({ ...positionsTableFilter, state: '-1' });
                      setCurrentState('-1');
                    }
                  }}
                >
                  <option key="-1" value="-1">{t('State_Filter')}</option>
                  {statesListOptions.map((row: SelectOption) => (
                    <option key={row.id} value={row.id}>
                      {row.value}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  onClick={() => setShowReportFilterModal(true)}
                  className="clc-position-report-filter-btn"
                  variant="primary"
                  size="sm"
                  active
                >
                  <span className="ps-2 pe-2">
                    {`${t('Position_Report_Filters')}`}
                    {appliedFilters ? <FaFilter className="ms-2" /> : <FiFilter className="ms-2" />}
                  </span>
                </Button>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 text-center">
              {loadingPositions ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <PositionsAvailableTable positions={data?.positionsAvailable} />
              )}
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="clc-report-title mt-4">{t('Active_PDP_By_ECC_Status')}</h1>
            <div className="row">
              <div className="col-sm-12 col-md-3 my-2 ms-auto">
                <Form.Select
                  className="pill-select btn btn-primary custom-select mb-2"
                  defaultValue={eccStateFilter.eccState}
                  onChange={(e) => {
                    const state = statesListOptions.find((p) => p.id.toString() === e.target.value);
                    if (state) {
                      setEccStateFilter({ eccState: state.value });
                    }
                    if (e.target.value === '-1') {
                      setEccStateFilter({ eccState: '-1' });
                    }
                  }}
                >
                  <option key="-1" value="-1">{t('State_Filter')}</option>
                  {statesListOptions.map((row: SelectOption) => (
                    <option key={row.id} value={row.id}>
                      {row.value}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 text-center">
              {loadingEccActivePDPs ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <ActivePDPsTable eccData={eccData} />
              )}
            </div>
          </div> */}
        </div>
      </div>
    </Container>
  );
}

export default CLCReports;
