import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SalaryData, ExperienceSalaryData } from '../../types/midcouncilreport.types';
import { formatCurrency } from '../../utils';

interface SalaryTableProps {
  data: SalaryData[];
}

export const SalaryTable: React.FC<SalaryTableProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const [selectedPosition, setSelectedPosition] = useState<SalaryData>(data[0]);

  return (
    <div className="mb-5">
      <h5 className="text-center">{t('Average_Salary_By_Experience_Level')}</h5>
      <Form.Select
        className="pill-select btn btn-primary custom-select mb-2"
        defaultValue={data[0].position}
        onChange={(e) => {
          const position = data.find((p) => p.position === e.target.value);
          if (position) setSelectedPosition(position);
        }}
      >
        {data.map((row: SalaryData) => (
          <option key={row.position} value={row.position}>
            {row.position}
          </option>
        ))}
      </Form.Select>
      <table className="table table-striped table-bordered">
        <thead className="linktableheader">
          <tr>
            <th scope="col">{t('ExperienceLevel')}</th>
            <th scope="col">{t('Avg_Min_Effective_Salary')}</th>
            <th scope="col">{t('Average_Expected_Salary')}</th>
          </tr>
        </thead>
        <tbody>
          {selectedPosition?.AvgSalaryByExperience?.map((row: ExperienceSalaryData) => (
            <tr key={`position-${row.experienceLevel}`}>
              <td>{row.experienceLevel}</td>
              <td>{formatCurrency(row.mdpAvgSalary)}</td>
              <td>{formatCurrency(row.pdpAvgSalary)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
