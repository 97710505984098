export interface ApplicantPositionData {
  position: string,
  available: string,
  avgEffectiveSalary: number,
  firstCall: string,
  seekers: string,
}

export interface ApplicantSummaryData {
  positions: number,
  seekers: number,
  intercultural: number,
  englishPositions: number,
  spanishPositions: number,
  koreanPositions: number,
  otherPositions: number
}

export interface MatchingReportResponseData {
  data: {
    matchesByStatusData: MatchesByStatusData;
    pdpMatchDistExperienceData: GraphData;
    pdpMatchDistGenderData: GraphData;
    pdpMatchDistRaceData: GraphData;
  }
}

export interface MatchesByStatusData {
  invitedToApply: number;
  beingConsidered: number;
  noLongerConsidered: number;
}

export interface ApplicationsPositionsResponse {
  data: {
    summary: ApplicantSummaryData,
    positionsAvailable: ApplicantPositionData[],
    eccGenderDistribution: EccGenderData,
    eccRaceDistribution: EccRaceData,
    eccActivePDPDistribution: CommunityTypeDistributionData,
    pdpActiveRaceDistribution: ActivePDPCountData,
    pdpActiveGenderDistribution: ActivePDPCountData
  }
}

export interface ApplicantsPositionsState {
  summary: ApplicantSummaryData;
  positionsAvailable: ApplicantPositionData[];
  eccGenderDistribution: EccGenderData,
  eccRaceDistribution: EccRaceData,
}

export interface GraphDataset {
  label?: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

export interface PieGraphDataset {
  label?: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

export interface CommunityTypeCount {
  community: string;
  count: number;
}

export interface CommunityTypeDistributionData{
  ordained: CommunityTypeCount[];
  nonOrdained: CommunityTypeCount[];
  candidate: CommunityTypeCount[];
}

export interface GraphData {
  labels: string[];
  datasets: GraphDataset[];
}

export interface PieGraphData {
  labels: string[];
  datasets: PieGraphDataset[];
}

export interface GenderCount {
  gender: string;
  count: number;
}

export interface EccGenderData {
  graphData: GraphData;
  total: GenderCount[];
  ordained: GenderCount[];
  candidate: GenderCount[];
  nonOrdained: GenderCount[];
}

export interface RaceCount {
  race: string;
  count: number;
}

export interface EccRaceData {
  total: RaceCount[];
  ordained: RaceCount[];
  candidate: RaceCount[];
  nonOrdained: RaceCount[];
  graphData: GraphData;
}

export interface ActivePDPCountData {
  graphData: PieGraphData;
}

export interface CLCReportFilterData {
  communityType: number[];
  experienceLevel: number[];
  employmentType: number[];
  eccStatus: number[];
  state: string;
}

export interface MatchReportsFilterData {
  eccStatus: number[];
  race: number[];
  gender: number[];
}

export const MatchReportsFilterDefaultData: MatchReportsFilterData = {
  eccStatus: [],
  race: [],
  gender: [],
};

export const MatchesByStatusDefaultData: MatchesByStatusData = {
  invitedToApply: 0,
  beingConsidered: 0,
  noLongerConsidered: 0,
};

export const CLCReportFilterDefaultData: CLCReportFilterData = {
  communityType: [],
  experienceLevel: [],
  employmentType: [],
  eccStatus: [],
  state: '',
};

export const GraphDataDefaultData: GraphData = {
  labels: [],
  datasets: [],
};

export const PieGraphDataDefaultData: PieGraphData = {
  labels: [],
  datasets: [],
};

export const CommunityTypeDistributionDefaultData: CommunityTypeDistributionData = {
  ordained: [],
  nonOrdained: [],
  candidate: [],
};

export const EccGenderDefaultData: EccGenderData = {
  graphData: GraphDataDefaultData,
  total: [],
  ordained: [],
  candidate: [],
  nonOrdained: [],
};

export const EccRaceDefaultData: EccRaceData = {
  total: [],
  ordained: [],
  candidate: [],
  nonOrdained: [],
  graphData: GraphDataDefaultData,
};

export const ApplicantSummaryDefaultData: ApplicantSummaryData = {
  positions: 0,
  seekers: 0,
  intercultural: 0,
  englishPositions: 0,
  spanishPositions: 0,
  koreanPositions: 0,
  otherPositions: 0,
};

export const ApplicantsPositionsDefaultState: ApplicantsPositionsState = {
  summary: ApplicantSummaryDefaultData,
  positionsAvailable: [],
  eccGenderDistribution: EccGenderDefaultData,
  eccRaceDistribution: EccRaceDefaultData,
};
