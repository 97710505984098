import { useState, useEffect } from 'react';
import { MidCouncilReportData } from '../types/midcouncilreport.types';
import api from '../services/api.service';

export const useMidCouncilReport = (isLoggedIn: boolean) => {
  const [data, setData] = useState<MidCouncilReportData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      api.get('mid-councils-report/')
        .then((response) => {
          setData(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setIsLoading(false);
        });
    }
  }, [isLoggedIn]);

  return { data, isLoading, error };
};
