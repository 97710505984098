import {
  useEffect,
  useState,
  Suspense,
  useRef,
} from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import PDPService from '../services/pdp.service';
import {
  PDPData,
  IExperienceLevel,
  ServiceData,
  ServiceResponseData,
  WorkData,
  WorkResponseData,
  PDPNarrativeData,
  PDPNarrativesResponseData,
  GeographicalOptionData,
  GeographicalOptionResponseData,
  GeographicalOptionDefaultData,
  PDPResponseData,
  StatementModalResponseData,
  StatementModalData,
  statementDefault,
  TrainingEducationData,
  TrainingEducationDefaultData,
  TrainingEducationResponseData,
} from '../types/pdp.types';
import { IPositionTypeListResponse, IPositionTypeList } from '../services/mdp.service';
import {
  PersonalInfoData,
  PersonalInfoDefaultData,
  PersonalInfoResponseData,
  CallingInfoResponseData,
  CallingInfoData,
} from '../types/profile.types';
import api from '../services/api.service';
import {
  pdpState,
  experienceLevelOptionsState,
  userProfileState,
  callingInfoState,
} from '../services/state.service';
import {
  UserProfile,
} from '../types/user.types';
import SuspenseLoading from './generic/SuspenseLoading';
import UserService from '../services/user.service';
import ProfileInformation, { ProfileInformationProps } from './pdp/ProfileInformation';
import { getFullName } from '../utils';
import EducationTraining from './pdp/EducationTraining';
import WorkExperience from './pdp/WorkExperience';
import ServiceToTheChurch from './pdp/ServiceToTheChurch';
import StatementOfFaith from './pdp/StatementOfFaith';
import PDPNarratives from './pdp/PDPNarratives';
import PDPOptionalLinks from './pdp/PDPOptionalLinks';
import SexualMisconduct from './pdp/SexualMisconduct';
import PDPReferences from './pdp/PDPReferences';
import CommunityTypeOptions from './pdp/CommunityTypeOptions';

interface OptionalLinkData {
  id: number,
  pdp: number,
  linkTitle: string,
  linkDescription: string,
  linkUrl: string,
}

interface OptionalLinkResponseData {
  data: OptionalLinkData[],
}

interface ReferenceData {
  id: number,
  pdp: number,
  name: string,
  relation: string,
  phone: string,
  email: string,
}

interface ReferenceResponseData {
  data: ReferenceData[],
}

interface ViewPDPFormProps {
  pdpId?: string;
}

function ViewPDPForm({ pdpId }: ViewPDPFormProps): JSX.Element {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const [pdp, setPDP] = useRecoilState<PDPData>(pdpState);
  const [positionTypeList, setPositionTypeList] = useState<IPositionTypeList[]>([]);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [eccInformation, setECCInformation] = useRecoilState<CallingInfoData>(callingInfoState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>();
  const [serviceData, setServiceData] = useState<ServiceData[]>([]);
  const [allWorkData, setAllWorkData] = useState<WorkData[]>([]);
  const [optionalLink, setOptionalLink] = useState<OptionalLinkData[]>([]);
  const [reference, setReference] = useState<ReferenceData[]>([]);
  const [PDPNarrativesData, setPDPNarrativesData] = useState<PDPNarrativeData[]>([]);
  const [geoOptions, setGeoOptions] = useState<GeographicalOptionData>(GeographicalOptionDefaultData);
  const [currentStatement, setCurrentStatement] = useState<StatementModalData>(statementDefault);
  const [userProfile, setUserProfileState] = useRecoilState<UserProfile>(userProfileState);
  const [allEducationData, setAllEducationData] = useState<TrainingEducationData>(TrainingEducationDefaultData);
  const [misconductInfo, setMisconductInfo] = useState<PersonalInfoData>(PersonalInfoDefaultData);
  const [callingInfo, setCallingInfo] = useState<CallingInfoData[]>([]);
  const [displayPrint, setDisplayPrint] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);
  const [pdpIdState, setPDPIdState] = useState<string | undefined>(pdpId);
  const [profileInfo, setProfileInfo] = useState<ProfileInformationProps | null>(null);

  useEffect(() => {
    if (pdpId) {
      setPDPIdState(pdpId);
    } else if (params.id) {
      setPDPIdState(params.id);
    }
  }, [pdpId, params.id]);

  useEffect(() => {
    if (userProfile && eccInformation) {
      setProfileInfo(() => ({
        header: t('ProfileInformation'),
        pdpId: pdpIdState || '0',
        fullName: getFullName(userProfile),
        email: userProfile.email,
        pronouns: userProfile.pronouns,
        addressOne: userProfile.addressOne,
        fullAddress: userProfile.fullAddress,
        phoneOne: userProfile.phoneOne,
        phoneTwo: userProfile.phoneTwo,
        eccStatusDescription: eccInformation.eccStatusDescription,
        presbyteryNonOrdainedDescription: eccInformation.presbyteryNonOrdainedDescription,
        congregationDescription: eccInformation.congregationDescription,
        presbyteryDescription: eccInformation.presbyteryDescription,
        ordinationDate: eccInformation.ordinationDate,
      }));
    }
  }, [userProfile, eccInformation]);

  useEffect(() => {
    if (pdpIdState) {
      Promise.all([
        UserService.GetProfile().then((profile: UserProfile) => {
          setUserProfileState(profile);
          if (profile.middleName === null) {
            setUserProfileState((p) => ({ ...p, middleName: '' }));
          }
        }),

        api.get('/positiontypelist/').then(
          (response: IPositionTypeListResponse) => (setPositionTypeList(response.data.options)),
        ),

        PDPService.GetTrainingEducation((pdpIdState)).then(
          (response: TrainingEducationResponseData) => (setAllEducationData(response.data.options)),
        ),

        api.get(`/pdp/${pdpIdState}/`).then(
          (response: PDPResponseData) => (setPDP(response.data)),
        ),

        api.get(`/pdp/${pdpIdState}/service/`).then(
          (response: ServiceResponseData) => (setServiceData(response.data)),
        ),

        api.get(`/pdp/${pdpIdState}/workexperience/`).then(
          (response: WorkResponseData) => (setAllWorkData(response.data)),
        ),

        api.get(`/pdp/${pdpIdState}/narrative/`).then(
          (response: PDPNarrativesResponseData) => {
            const sortedData = response.data.sort((a, b) => a.displayOrder - b.displayOrder);
            setPDPNarrativesData(sortedData);
          },
        ),

        api.get(`/pdp/${pdpIdState}/optionallinks/`).then(
          (response: OptionalLinkResponseData) => (setOptionalLink(response.data)),
        ),

        api.get(`/pdp/${pdpIdState}/reference/`).then(
          (response: ReferenceResponseData) => (setReference(response.data)),
        ),

        api.get('/profile/personalinfo/').then((response: PersonalInfoResponseData) => {
          setMisconductInfo(response.data[0]);
        }),

        api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
          setECCInformation(response.data[0]);
        }),

        api.get('/profile/statement/').then(
          (response: StatementModalResponseData) => {
            if (response.data) {
              setCurrentStatement(response.data[0]);
            }
          },
        ),
      ]).then(() => {
        setDisplayPrint(true);
      });
    }
  }, [pdpIdState, i18n.language]);

  useEffect(() => {
    if (experienceLevels.length === 0) {
      const list = [...experienceLevels];
      list.splice(list.findIndex((p) => p.value === 'No Experience/First Ordained Call'), 1);
      setFilteredExpLevels(list);

      api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
        setCallingInfo(response.data);
        if (response.data[0].eccStatusValue === 'candidate') {
          setFilteredExpLevels(list.filter((p) => p.value === 'First Ordained Call'));
        }
      });
    }
  }, [experienceLevels]);

  const renderPositionTypeWithExperienceLevel = (item: any, option: any) => {
    const expLevel = filteredExpLevels?.find((level: any) => level.id === option.experienceLevel);
    return (
      <div>
        {`${item.positionType} ${expLevel ? `(${expLevel.displayName})` : ''}`}
      </div>
    );
  };

  return (
    <div className="col-11 my-4 mx-auto print-content">
      {displayPrint && (
        <div className="text-end">
          <Button
            className="mb-3 ms-auto printbutton"
            variant="primary"
            size="sm"
            active
            onClick={() => window.print()}
          >
            <FaEdit />
            {` ${t('Print_Btn')}`}
          </Button>
        </div>
      )}
      <div ref={printRef} className="col-12 mx-auto">
        <div className="title text-center">
          {`${t('PDP.PDP_Title')} ID# ${pdpIdState}`}
        </div>
        {profileInfo?.pdpId && <ProfileInformation profileInfo={profileInfo} />}

        {/* Preferences */}
        <div className="title text-center">
          {t('PDP.Preferences')}
        </div>
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-7">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Employment_Type')}
                </div>
                {pdp.employmentType?.description}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {`${t('PDP.Position_Type_Title')} (${t('ExperienceLevel')})`}
                </div>
                <Suspense fallback={<SuspenseLoading />}>
                  <div>
                    {pdp.positionTypes.map((option: any) => (
                      <div key={`postype-${option.positionType}`}>
                        {positionTypeList.map((item: any) => (
                          (item.id === option.positionType)
                            ? (
                              <div key={`postype-item-${item.id}`}>
                                {renderPositionTypeWithExperienceLevel(item, option)}
                              </div>
                            )
                            : null))}
                      </div>
                    ))}
                  </div>
                </Suspense>
              </div>
            </div>
            <div className="col-5">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Min_Expected_Salary')}
                </div>
                {pdp.minimumExpectedSalary}
              </div>
              <CommunityTypeOptions communityTypes={pdp.communityTypes} />
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Housing_Type')}
                </div>
                {pdp.housingTypeDescription}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Available_To_Match')}
                </div>
                <div>
                  {callingInfo?.map((info: any) => (
                    <div key="calling-info-actively-seeking">
                      {info.seeking ? (t('Yes')) : (t('No'))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <EducationTraining educationTraining={allEducationData} />
        <WorkExperience workExperience={allWorkData} />
        <ServiceToTheChurch service={serviceData} />
        <StatementOfFaith statementOfFaith={currentStatement.text || ''} />
        <PDPNarratives narratives={PDPNarrativesData} />
        <PDPOptionalLinks optionalLinks={optionalLink} />
        <PDPReferences references={reference} />
        <SexualMisconduct sexualMisconduct={misconductInfo} />
      </div>
    </div>
  );
}

ViewPDPForm.defaultProps = {
  pdpId: '',
};

export default ViewPDPForm;
