import {
  useState,
  useEffect,
} from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import PDPService from '../services/pdp.service';
import {
  GeographicalOptionData,
  PDPData,
  PDPResponseData,
} from '../types/pdp.types';

import { pdpStepState, pdpState, geographicalOptionState } from '../services/state.service';
import { StepWizardData, DefaultStepWizardData } from '../types/generic.types';
import { PDPSteps } from '../constants/PDPSteps';
import StepWizard from '../components/generic/StepWizard';

function PDPEdit(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState<StepWizardData>(DefaultStepWizardData);
  const [pdp, setPDP] = useRecoilState<PDPData>(pdpState);
  const [steps, setSteps] = useRecoilState<StepWizardData[]>(pdpStepState);
  const [geoOptions, setGeoOptions] = useRecoilState<GeographicalOptionData>(geographicalOptionState);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSteps(PDPSteps);

    // @todo: Calculate activestep on load. For now, it's the first one.
    setActiveStep(PDPSteps[0]);
  }, []);

  const handleStepChange = (): void => {
    if (activeStep.key === 'geographicaloptions') {
      if (geoOptions.id > 0) {
        PDPService.UpdateGeographicalOptions(geoOptions);
      } else {
        PDPService.CreateGeographicalOptions(geoOptions);
      }
    }
    PDPService.UpdatePDP(pdp);
  };

  useEffect(() => {
    if (params.id) {
      PDPService.GetPDPData(params.id)
        .then((response: PDPResponseData) => {
          setPDP(response.data);
          setLoading(false);
        });
    }
  }, [params.id, setPDP]);

  const formSubmit = (): void => {
    PDPService.UpdatePDP({
      ...pdp,
      submitted: true,
      submittedDate: new Date(),
      active: true,
    })
      .then(() => {
        navigate('/');
      });
  };

  return !loading ? (
    <StepWizard
      steps={steps}
      handleStepChange={handleStepChange}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      formSubmit={formSubmit}
      id={pdp.id}
    />
  ) : (
    <Spinner
      animation="border"
      size="sm"
    />
  );
}

export default PDPEdit;
