import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import { UserProfile } from '../types/user.types';
import { useMidCouncilReport } from '../hooks/useMidCouncilReport';
import { SalaryTable } from '../components/reports/SalaryTable';
import { LanguageTable } from '../components/reports/LanguageTable';
import PieGraph from '../components/generic/PieGraph';
import BackToDashboard from '../components/generic/BackToDashboard';

function MidCouncilReport(): JSX.Element {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const { data, isLoading, error } = useMidCouncilReport(userProfile.isLoggedIn);

  if (isLoading) {
    return (
      <div
        className="m-3 d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="m-3 d-flex justify-content-center align-items-center"
      >
        {error}
      </div>
    );
  }

  return (
    <div className="container-fluid mt-3">
      <BackToDashboard />
      <h1 className="clc-report-title mb-3">
        {t('Mid_Councils_Report_Center')}
      </h1>
      <div className="row mb-3">
        <div className="offset-md-1 col-md-5 col-sm-12">
          <SalaryTable data={data?.salaryData || []} />
        </div>
        <div className="col-md-5 col-sm-12">
          <LanguageTable data={data?.languageData || []} />
          {data && (
            <div className="col-print-center">
              <PieGraph
                graphData={data.mdpCommunityTypeData}
                title={t('Active_MDPs_By_Community_Type')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MidCouncilReport;
