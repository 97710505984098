import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import PDPService from '../services/pdp.service';
import {
  CommunityTypeResponseData,
  SizeRangeData,
  SizeRangeResponseData,
  WorkData,
  WorkResponseData,
} from '../types/pdp.types';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import ConfirmModal from './generic/ConfirmModal';
import WorkExperienceModal from './WorkExperienceModal';
import { StepProps, SelectOption } from '../types/generic.types';
import { useStepValid } from '../hooks/useStepValid';
import { pdpStepState } from '../services/state.service';
import SanitizeHTML from '../services/html.service';
import HelpPopup from './generic/HelpPopup';

function WorkExperience({
  id,
}: StepProps): JSX.Element {
  const initialWorkValues = {
    id: 0,
    pdp: id,
    organization: '',
    position: '',
    name: '',
    size: '',
    country: '',
    city: '',
    state: '',
    community: '',
    start: '',
    end: '',
    current: false,
    responsibilities: '',
  };

  const { t } = useTranslation();

  // Work Data: All, refresh, and currently selected
  const [allWorkData, setAllWorkData] = useState<WorkData[]>([]);
  const [refreshWorkData, setRefreshWorkData] = useState(false);
  const [selectedWork, setSelectedWork] = useState<WorkData>(initialWorkValues);

  // Contols for modal
  const [showDelete, setShowDelete] = useState(false);
  const [showNewEdit, setShowNewEdit] = useState(false);

  // Options from DB for TypeAheads in WorkExperienceModal
  const [sizeRange, setSizeRange] = useState<SizeRangeData[]>([]);
  const [communityType, setCommunityType] = useState<SelectOption[]>([]);

  const requiredFields = useMemo(() => [], []);
  useStepValid('workexperience', allWorkData, requiredFields, pdpStepState);

  const importWorkExperience = (): Promise<void> => (
    PDPService.GetWork(id, true).then(
      (response: WorkResponseData) => (setAllWorkData(response.data)),
    )
  );

  useEffect(() => {
    PDPService.GetWork(id, false).then(
      (response: WorkResponseData) => (setAllWorkData(response.data)),
    );
  }, [id, refreshWorkData]);

  useEffect(() => {
    PDPService.GetSizeRange().then(
      (response: SizeRangeResponseData) => (setSizeRange(response.data.options)),
    );
    PDPService.GetCommunityTypes().then(
      (response: CommunityTypeResponseData) => {
        const dataset = response.data.options.map((option: any) => ({
          id: option.id,
          value: option.description,
        }));
        setCommunityType(dataset);
      },
    );
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && selectedWork) {
      PDPService.DeleteWork(selectedWork).then(() => {
        setShowDelete(false);
        setRefreshWorkData((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [selectedWork]);

  const closeNewEdit = useCallback((result: boolean, work: WorkData): void => {
    if (result && work) {
      let createOrUpdate = PDPService.CreateWork;
      if (work.id > 0) {
        createOrUpdate = PDPService.UpdateWork;
      }

      createOrUpdate(work).then(() => {
        setShowNewEdit(false);
        setRefreshWorkData((r: boolean) => !r);
      });
    } else {
      setShowNewEdit(false);
    }
  }, []);

  return (
    <div>
      <WorkExperienceModal
        show={showNewEdit}
        selectedWork={selectedWork}
        callback={closeNewEdit}
        sizeRange={sizeRange}
        communityType={communityType}
      />
      <ConfirmModal
        show={showDelete}
        title={t('Delete_Work_Experience')}
        description={t('PDP.Work_Exp_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <div>
        <div className="title">{t('PDP.Work_Experience_Title')}</div>
        <div className="col-12 mx-auto">
          <SanitizeHTML html={t('PDP.Work_Experience_Description')} />
          <SanitizeHTML html={t('Copy_From_Profile_Description')} />
        </div>
        <br />
        <div className="d-flex">
          <Button
            className="mb-3 createbutton"
            variant="primary"
            size="sm"
            onClick={importWorkExperience}
          >
            {t('CopyFromMyProfile')}
          </Button>
          <Button
            onClick={() => {
              setSelectedWork(initialWorkValues);
              setShowNewEdit(true);
            }}
            className="mb-3 ms-auto createbutton"
            variant="primary"
            size="sm"
            active
          >
            {`+ ${t('PDP.Work_Create_New')}`}
          </Button>
        </div>
        <div className="border border-dark rounded-3">
          <Table responsive hover className="mb-3 linktable">
            <thead>
              <tr className="linktableheader">
                <th className="w-20">
                  {t('Organization')}
                </th>
                <th className="w-20">
                  {t('City')}
                </th>
                <th className="w-20">
                  {t('State')}
                </th>
                <th className="w-20">
                  {t('Country')}
                </th>
                <th className="w-20">
                  {t('PDP.Start_Date')}
                </th>
                <th className="w-20">
                  {t('PDP.End_Date')}
                </th>
                <th className="w-20">
                  {t('Actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {allWorkData.map((work: WorkData) => (
                <tr key={work.id} className="dividinglines">
                  <th>
                    {work.name}
                  </th>
                  <th>
                    {work.city}
                  </th>
                  <th>
                    {work.state}
                  </th>
                  <th>
                    {work.country}
                  </th>
                  <th>
                    {work.start}
                  </th>
                  <th>
                    {work.end}
                  </th>
                  <th>
                    <div>
                      <EditButton
                        label=""
                        handler={() => {
                          setSelectedWork(work);
                          setShowNewEdit(true);
                        }}
                      />
                    &nbsp;
                    &nbsp;
                      <DeleteButton
                        label=""
                        handler={() => {
                          setSelectedWork(work);
                          setShowDelete(true);
                        }}
                      />
                    &nbsp;
                      <HelpPopup
                        helpKey="tooltip-delete-pdp-work-experience"
                        placement="left"
                        trigger="click"
                        content={t('Delete_From_PDP_Tooltip')}
                      />
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
        </div>
        <br />
      </div>
    </div>
  );
}

export default WorkExperience;
