import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LanguageData } from '../../types/midcouncilreport.types';

interface LanguageTableProps {
  data: LanguageData[];
}

export const LanguageTable: React.FC<LanguageTableProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const [selectedState, setSelectedState] = React.useState<LanguageData>(data[0]);

  return (
    <div className="mb-5">
      <h5 className="text-center">{t('Active_PDPs_By_Language_And_State')}</h5>
      <Form.Select
        className="pill-select btn btn-primary custom-select mb-2"
        defaultValue={data[0].state}
        onChange={(e) => {
          const state = data.find((p) => p.state === e.target.value);
          if (state) setSelectedState(state);
        }}
      >
        {data.map((row: LanguageData) => (
          <option key={row.state} value={row.state}>
            {row.state}
          </option>
        ))}
      </Form.Select>
      <table className="table table-striped table-bordered">
        <thead className="linktableheader">
          <tr>
            <th scope="col">{t('Active_PDPs')}</th>
            <th scope="col">{t('English')}</th>
            <th scope="col">{t('Spanish')}</th>
            <th scope="col">{t('Korean')}</th>
          </tr>
        </thead>
        <tbody>
          {selectedState && (
            <tr>
              <td>{selectedState.activePDPs}</td>
              <td>{selectedState.english}</td>
              <td>{selectedState.spanish}</td>
              <td>{selectedState.korean}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
