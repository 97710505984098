import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import api from '../services/api.service';
import { userProfileState } from '../services/state.service';
import { UserProfile } from '../types/user.types';
import {
  ApplicantPositionData,
  ApplicationsPositionsResponse,
  ApplicantsPositionsState,
  ApplicantsPositionsDefaultState,
} from '../types/reports';
import { formatCurrency } from '../utils';

function ApplicantsPositions(): JSX.Element {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [data, setData] = useState<ApplicantsPositionsState>(ApplicantsPositionsDefaultState);

  useEffect(() => {
    if (userProfile.isLoggedIn) {
      api.get('applicantspositions/').then(
        (response: ApplicationsPositionsResponse) => setData(response.data),
      );
    }
  }, [userProfile]);

  return (
    <div className="container-fluid mt-5">
      <h1 className="text-center mb-5">
        {t('Applicants_Positions_Report')}
      </h1>
      <div className="row">
        {/* <div className="col-md-12 col-lg-6">
          Summary
        </div> */}
        <div className="col-md-12">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">{t('PDP.Position')}</th>
                <th scope="col">{t('MDPs_Available')}</th>
                <th scope="col">{t('Open_to_First_Call')}</th>
                <th scope="col">{t('Avg_Min_Effective_Salary')}</th>
                <th scope="col">{t('PDPs_Available')}</th>
              </tr>
            </thead>
            <tbody>
              {data.positionsAvailable?.map((row: ApplicantPositionData) => (
                <tr key={`position-${row.position}`}>
                  <td>{row.position}</td>
                  <td>{row.available}</td>
                  <td>{row.firstCall}</td>
                  <td>{formatCurrency(row.avgEffectiveSalary)}</td>
                  <td>{row.seekers}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ApplicantsPositions;
