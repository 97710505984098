import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApplicantPositionData } from '../../types/reports';
import { formatCurrency } from '../../utils';

function PositionsAvailableTable({ positions }: { positions: ApplicantPositionData[] }) {
  const { t } = useTranslation();

  return (
    <div>
      <Table responsive striped>
        <thead>
          <tr className="linktableheader">
            <th>{t('PDP.Position')}</th>
            <th>{t('MDPs_Available')}</th>
            <th>{t('Avg_Min_Effective_Salary')}</th>
            <th>{t('PDPs_Available')}</th>
          </tr>
        </thead>
        <tbody>
          {positions.map((item) => (
            <tr key={`report-${item.position}`}>
              <td>{item.position}</td>
              <td>{item.available}</td>
              <td>{formatCurrency(item.avgEffectiveSalary)}</td>
              <td>{item.seekers}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>

  );
}

export default PositionsAvailableTable;
