import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Table,
  Button,
  Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaLongArrowAltLeft, FaFilter } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { userProfileState } from '../services/state.service';
import { UserProfile } from '../types/user.types';
import api from '../services/api.service';
import BarGraph from '../components/generic/BarGraph';
import {
  GraphData,
  GraphDataDefaultData,
  MatchReportsFilterData,
  MatchReportsFilterDefaultData,
  MatchingReportResponseData,
  MatchesByStatusData,
  MatchesByStatusDefaultData,
} from '../types/reports';
import MatchReportsFilterModal from '../components/MatchReportsFilterModal';

function MatchReports() {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [genderData, setGenderData] = useState<GraphData>(GraphDataDefaultData);
  const [raceData, setRaceData] = useState<GraphData>(GraphDataDefaultData);
  const [experienceLevelData, setExperienceLevelData] = useState<GraphData>(GraphDataDefaultData);
  const [matchStatusData, setMatchStatusData] = useState<MatchesByStatusData>(MatchesByStatusDefaultData);
  const [showReportFilterModal, setShowReportFilterModal] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<boolean>(false);
  const [matchTableFilter, setMatchTableFilter] = useState<MatchReportsFilterData>(MatchReportsFilterDefaultData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userProfile.isLoggedIn) {
      const queryParams = new URLSearchParams();
      let hasFilters = false;
      const appendFilter = (filterKey: string, filterValue: number[] | string) => {
        if (Array.isArray(filterValue) && filterValue.length > 0) {
          queryParams.append(filterKey, filterValue.join(','));
          hasFilters = true;
          setLoading(true);
        } else if (filterValue.length === 0) {
          setLoading(true);
        } else if (typeof filterValue === 'string' && filterValue) {
          queryParams.append(filterKey, filterValue);
          setLoading(true);
        }
      };

      appendFilter('eccStatus', matchTableFilter.eccStatus);
      appendFilter('gender', matchTableFilter.gender);
      appendFilter('race', matchTableFilter.race);

      api.get(`matching-reports/?${queryParams.toString()}`).then((response: MatchingReportResponseData) => {
        setGenderData({
          ...response.data.pdpMatchDistGenderData,
          labels: response.data.pdpMatchDistGenderData.labels.map((label) => t(label)),
        });
        setRaceData({
          ...response.data.pdpMatchDistRaceData,
          labels: response.data.pdpMatchDistRaceData.labels.map((label) => t(label)),
        });
        setExperienceLevelData({
          ...response.data.pdpMatchDistExperienceData,
          labels: response.data.pdpMatchDistExperienceData.labels.map((label) => t(label)),
        });
        setMatchStatusData(response.data.matchesByStatusData);
      }).catch(() => {}).finally(() => { setLoading(false); });
      setAppliedFilters(hasFilters);
    }
  }, [userProfile, matchTableFilter]);

  const setTableFilters = useCallback((result: boolean, filter: MatchReportsFilterData): void => {
    if (result) {
      setMatchTableFilter(
        {
          gender: filter.gender,
          race: filter.race,
          eccStatus: filter.eccStatus,
        },
      );
    }
    setShowReportFilterModal(false);
  }, []);

  return (
    <Container fluid>
      <MatchReportsFilterModal
        show={showReportFilterModal}
        hide={() => setShowReportFilterModal(false)}
        callback={setTableFilters}
      />
      <Link to="/" className="dashboard-link">
        <FaLongArrowAltLeft />
        <span>{t('Back_to_Dashboard')}</span>
      </Link>
      <div className="row">
        <div className="col-sm-12 col-md-12 mb-4 col-print-center">
          <h1 className="clc-report-title mt-3">{t('CLC_Match_Report_Center')}</h1>
        </div>
        <div className="col-sm-12 col-md-4 col-print-center">
          <BarGraph title={t('PDP_Match_Statuses_By_Gender')} graphData={genderData} />
        </div>
        <div className="col-sm-12 col-md-4 col-print-center">
          <BarGraph title={t('PDP_Match_Statues_By_Race')} graphData={raceData} />
        </div>
        <div className="col-sm-12 col-md-4 col-print-center">
          <BarGraph title={t('PDP_Match_Statuses_By_Exp_Level')} graphData={experienceLevelData} />
        </div>
        <div>
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="clc-report-title mt-3">{t('Matches_By_PDP_Status')}</h1>
            <div className="row">
              <div className="my-2 d-flex justify-content-end">
                <Button
                  onClick={() => setShowReportFilterModal(true)}
                  className="clc-position-report-filter-btn"
                  variant="primary"
                  size="sm"
                  active
                >
                  {`${t('PDP_Match_Filters')}`}
                  {appliedFilters ? <FaFilter className="ms-2" /> : <FiFilter className="ms-2" />}
                </Button>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 text-center">
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <Table responsive striped>
                  <thead>
                    <tr className="linktableheader">
                      <th>{t('Invited_To_Apply')}</th>
                      <th>{t('Being_Considered')}</th>
                      <th>{t('No_Longer_Considered')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={0}>
                      <td>{matchStatusData.invitedToApply}</td>
                      <td>{matchStatusData.beingConsidered}</td>
                      <td>{matchStatusData.noLongerConsidered}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default MatchReports;
