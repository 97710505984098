import {
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import Modal from 'react-bootstrap/Modal';
import { Form, Button } from 'react-bootstrap';
import {
  WorkData,
  SizeRangeData,
} from '../types/pdp.types';
import { STATES, ORG_TYPE } from '../types/constants';
import CLCInput from './generic/CLCInput';
import CLCSelect from './generic/CLCSelect';
import { cleanText } from '../utils';
import { SelectOption } from '../types/generic.types';

type WorkExperienceModalProps = {
  show: boolean,
  selectedWork: WorkData,
  callback: (_result: boolean, _work: WorkData) => void,
  sizeRange: SizeRangeData[],
  communityType: SelectOption[],
}

function WorkExperienceModal({
  show,
  selectedWork,
  callback,
  sizeRange,
  communityType,
}: WorkExperienceModalProps): JSX.Element {
  const { t } = useTranslation();
  const [work, setWork] = useState(selectedWork);
  const [error, setError] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);
  const [validResponsibilities, setValidResponsibilities] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState(false);
  const editorRef = useRef<any>(null);
  const log = (): any => {
    const characterLimit = 1700;

    if (editorRef.current !== null) {
      const cleanedText = cleanText(editorRef.current.getContent());
      if (cleanedText.length > characterLimit) {
        setShowAlert(true);
        setValidResponsibilities(false);
      } else {
        setShowAlert(false);
        setValidResponsibilities(true);
      }
      setWork((p: any) => ({
        ...p, responsibilities: editorRef.current.getContent(), initial: false,
      }));
    }
  };

  useEffect(() => {
    setWork(selectedWork);
  }, [selectedWork]);

  useEffect(() => {
    const validateDates = (): boolean => {
      if (work.start && work.end) {
        const start = new Date(work.start);
        const end = new Date(work.end);

        if (start instanceof Date && end instanceof Date) {
          if (start > end) {
            setError(t('End_Date_Must_Be_After_Start_Date'));
            return false;
          }
          setError('');
          return true;
        }
      } else if (work.start && work.current) {
        setError('');
        return true;
      }

      return false;
    };

    const validDates = validateDates();

    if (work.organization === 'Church'
      && work.position
      && work.name
      && work.city
      && (work.state || work.country)
      && validDates
      && work.size
      && work.community
      && validResponsibilities
    ) {
      setValid(true);
    } else if (work.organization === 'Other'
      && work.position
      && work.name
      && work.city
      && validResponsibilities
      && (work.state || work.country)
      && validDates
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [work]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setWork((x: any) => ({ ...x, [name]: value }));
  };

  return (
    <Modal
      show={show}
      onHide={() => callback(false, work)}
      animation={false}
      className="modal"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {work.id > 0 ? t('PDP.Edit_Work') : t('PDP.Work_Create_New')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="bodyheader required">
              {t('Organization')}
            </Form.Label>
            <Form.Select
              value={work.organization || ''}
              name="organization"
              onChange={handleSelectChange}
              className="mb-3"
            >
              <option key="" value="">
                {`-- ${t('Select')} ${t('Organization')} --`}
              </option>
              {(ORG_TYPE?.map((option: any) => (
                <option key={(option.value ? option.value : option)} value={(option.value ? option.value : option)}>
                  {(option.value ? option.value : t(option))}
                </option>
              )))}
            </Form.Select>
            {work.organization !== '' && (
              <CLCInput
                data={work}
                formLabel={t('PDP.Position')}
                stateSetter={setWork}
                inputName="position"
                inputType="text"
              />
            )}
            {(work.organization !== '') && (
              work.organization !== 'Other'
                ? (
                  <CLCInput
                    data={work}
                    formLabel={t('Church_Name')}
                    stateSetter={setWork}
                    inputName="name"
                    inputType="text"
                  />
                )
                : (
                  <CLCInput
                    data={work}
                    formLabel={t('Organization_Name')}
                    stateSetter={setWork}
                    inputName="name"
                    inputType="text"
                  />
                )
            )}
            {work.organization !== '' && (
              <div className="row">
                <div className="col-4">
                  <CLCInput
                    data={work}
                    formLabel={t('City')}
                    stateSetter={setWork}
                    inputName="city"
                    maxLength={500}
                  />
                </div>
                <div className="col-4">
                  <CLCSelect
                    required={work.country === null || work.country === ''}
                    data={work}
                    formLabel={t('State')}
                    inputName="state"
                    stateSetter={setWork}
                    options={STATES}
                  />
                </div>
                <div className="col-4">
                  <CLCInput
                    required={false}
                    data={work}
                    formLabel={t('Country')}
                    stateSetter={setWork}
                    inputName="country"
                    maxLength={500}
                  />
                </div>
              </div>
            )}
            {(work.organization !== 'Other') && work.organization !== '' && (
              <CLCSelect
                data={work}
                formLabel={t('PDP.Size')}
                inputName="size"
                stateSetter={setWork}
                options={sizeRange}
              />
            )}
            {(work.organization !== 'Other') && work.organization !== '' && (
              <CLCSelect
                data={work}
                formLabel={t('PDP.Community')}
                inputName="community"
                stateSetter={setWork}
                options={communityType}
              />
            )}
            {work.organization !== '' && (
              <div className="row">
                <div className="my-4">
                  <Form.Group className="text-start col-12">
                    <Form.Label className="bodyheader">
                      {t('PDP.Responsibilities')}
                    </Form.Label>
                    {showAlert && (
                      <div className="col-lg-8 col-12 mx-auto text-center error-text">
                        {t('1000_Character_Limit_Exceeded')}
                      </div>
                    )}
                    <Form>
                      <Editor
                        onInit={(evt: any, editor: any): any => { editorRef.current = editor; }}
                        initialValue={work?.responsibilities || ''}
                        init={{
                          menubar: false,
                          statusbar: false,
                          branding: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code autoresize',
                          ],
                          toolbar: 'fontselect | formatselect | '
                            + 'alignleft aligncenter alignright alignjustify |'
                            + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
                          contextmenu: false,
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          init_instance_callback(editor: any) {
                            editor.on('blur', () => log());
                          },
                        }}
                      />
                    </Form>
                  </Form.Group>
                </div>
                <div className="col-3">
                  <CLCInput
                    data={work}
                    formLabel={t('PDP.Start_Date')}
                    stateSetter={setWork}
                    inputName="start"
                    inputType="date"
                  />
                </div>
                <div className="col-3">
                  {!work.current && (
                    <CLCInput
                      data={work}
                      formLabel={t('PDP.End_Date')}
                      stateSetter={setWork}
                      inputName="end"
                      inputType="date"
                      required
                    />
                  )}
                </div>
                <div className="col-6">
                  <Form.Label />
                  <Form.Check
                    className={`fw-bold${work.end ? '' : ' required'}`}
                    type="checkbox"
                    label={t('Work_Experience_Current')}
                    checked={work.current}
                    onChange={() => setWork((l) => ({ ...l, current: !work.current, end: '' }))}
                    maxLength={500}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <Form.Text className="text-danger">{error}</Form.Text>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, work))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => (callback(true, work))}
          disabled={!valid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkExperienceModal;
