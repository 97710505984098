import {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  useForm,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SelectOptionsData } from '../types/selectoptions.types';
import {
  genderOptionsState,
  raceOptionsState,
  openToOptionssState,
} from '../services/state.service';
import { MatchReportsFilterData, MatchReportsFilterDefaultData } from '../types/reports';
import { OPEN_TO_OPTIONS } from '../types/constants';

type MatchReportsFilterModalProps = {
  callback: (_result: boolean, _match: MatchReportsFilterData) => void,
  show: boolean,
  hide: () => void,
}

export interface AllButtonFilters {
  eccStatus: boolean;
  gender: boolean;
  race: boolean;
}

export const AllButtonDefaultFilters: AllButtonFilters = {
  eccStatus: true,
  gender: true,
  race: true,
};

function MatchReportsFilterModal({
  show,
  hide,
  callback,
}: MatchReportsFilterModalProps): JSX.Element {
  const { t } = useTranslation();
  const [reportFilters, setReportFilters] = useState<MatchReportsFilterData>(MatchReportsFilterDefaultData);
  const [isActive, setIsActive] = useState<AllButtonFilters>(AllButtonDefaultFilters);
  const [currentReportFilters, setCurrentReportFilters] = useState<MatchReportsFilterData>(MatchReportsFilterDefaultData);
  const [openTo, setOpenTo] = useState<SelectOptionsData[]>([]);
  const raceOptions = useRecoilValue<SelectOptionsData[]>(raceOptionsState);
  const genderOptions = useRecoilValue<SelectOptionsData[]>(genderOptionsState);
  const openToOptions = useRecoilValue<SelectOptionsData[]>(openToOptionssState);
  const [isValid, setIsValid] = useState<boolean>(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const {
    handleSubmit,
  } = useForm<MatchReportsFilterData>();

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    setCurrentReportFilters(reportFilters);
    callback(true, {
      gender: reportFilters.gender,
      race: reportFilters.race,
      eccStatus: reportFilters.eccStatus,
      ...data,
    });
    hide();
  };

  const handleClose = () => {
    if (currentReportFilters.race.length > 0
      || currentReportFilters.gender.length > 0
      || currentReportFilters.eccStatus.length > 0) {
      setReportFilters(currentReportFilters);
    } else {
      setReportFilters(MatchReportsFilterDefaultData);
      setIsActive(AllButtonDefaultFilters);
    }
    hide();
  };

  useEffect(() => {
    setIsValid(
      (reportFilters.race.length > 0 || isActive.race)
    && (reportFilters.gender.length > 0 || isActive.gender)
    && (reportFilters.eccStatus.length > 0 || isActive.eccStatus),
    );
  }, [reportFilters, isActive]);

  useEffect(() => {
    setOpenTo(openToOptions.filter((option: SelectOptionsData) => OPEN_TO_OPTIONS.includes(option.description)));
  }, []);

  const eccStatusButtons = openTo.map((option: SelectOptionsData) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.eccStatus"
      className={`${reportFilters.eccStatus.includes(option.id)
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.eccStatus.includes(option.id)) {
          setReportFilters((p: MatchReportsFilterData) => ({
            ...p, eccStatus: reportFilters.eccStatus.filter((item: number) => item !== option.id),
          }));
        } else {
          setReportFilters((p: MatchReportsFilterData) => ({
            ...p, eccStatus: [...reportFilters.eccStatus, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          eccStatus: !p,
        }));
      }}
    >
      {option.description === 'CCE' ? t('Candidate') : t(option.description)}
    </Button>
  ));

  const raceTypeButtons = raceOptions.map((option: SelectOptionsData, index: any) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.race"
      className={`${reportFilters.race.includes(option.id)
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.race.includes(option.id)) {
          setReportFilters((p: any) => ({
            ...p, race: reportFilters.race.filter((item: any) => item !== option.id),
          }));
        } else {
          setReportFilters((p: any) => ({
            ...p, race: [...reportFilters.race, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          race: !p,
        }));
      }}
    >
      {raceOptions[index].description}
    </Button>
  ));

  const genderTypeButtons = genderOptions.map((option: SelectOptionsData, index: any) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.gender"
      className={`${reportFilters.gender.includes(option.id)
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.gender.includes(option.id)) {
          setReportFilters((p: any) => ({
            ...p, gender: reportFilters.gender.filter((item: any) => item !== option.id),
          }));
        } else {
          setReportFilters((p: any) => ({
            ...p, gender: [...reportFilters.gender, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          gender: !p,
        }));
      }}
    >
      {genderOptions[index].description}
    </Button>
  ));

  // Add the "Select All" button
  const selectAllButton = ((filterType: string) => (
    <Button
      id="select-all"
      key="select-all"
      name="reportFilters.eccStatus"
      className={`clc-report-filter-btn ms-3 mt-2 ${isActive[filterType as keyof AllButtonFilters]
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'}`}
      onClick={() => {
        setReportFilters((p: any) => ({
          ...p,
          [filterType]: [],
        }));
        setIsActive((p: any) => ({
          ...p,
          [filterType]: !p[filterType],
        }));
      }}
    >
      {t('All')}
    </Button>
  ));

  return (
    <Modal
      show={show}
      onHide={hide}
      animation={false}
      className="modalstyle"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('PDP_Match_Filters')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-auto ">

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div>
                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          {t('Gender')}
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('gender')}
                        {genderTypeButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div>
                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          <div className="checkbox-container">
                            {t('EcclesiasticalStatus')}
                          </div>
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('eccStatus')}
                        {eccStatusButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>

                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          {t('Race')}
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('race')}
                        {raceTypeButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>

              </div>
            </div>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              aria-label="submit"
            />
          </Form>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 text-end">
          <Button
            className="px-3"
            variant="primary"
            onClick={() => { submitRef.current?.click(); }}
            disabled={!isValid}
          >
            {t('Apply_Filters')}
          </Button>
          <Button
            className="mx-2"
            variant="danger"
            onClick={() => {
              callback(false, reportFilters);
              handleClose();
            }}
          >
            {t('PDP.Cancel')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MatchReportsFilterModal;
