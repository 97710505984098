import {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  useForm,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SelectOptionsData } from '../types/selectoptions.types';
import {
  experienceLevelOptionsState,
  employmentTypeOptionsState,
  communityTypesState,
  openToOptionssState,
} from '../services/state.service';
import { IExperienceLevel, EmploymentTypeData, CommunityTypeData } from '../types/pdp.types';
import { CLCReportFilterData, CLCReportFilterDefaultData } from '../types/reports';
import { OPEN_TO_OPTIONS, EXPERIENCE_LEVEL_OPTIONS, COMMUNITY_TYPE_OPTIONS } from '../types/constants';

type CLCReportsFilterModalProps = {
  callback: (_result: boolean, _match: CLCReportFilterData) => void,
  show: boolean,
  hide: () => void,
  state: string,
}

export interface AllButtonFilters {
  eccStatus: boolean;
  communityType: boolean;
  experienceLevel: boolean;
  employmentType: boolean;
}

export const AllButtonDefaultFilters: AllButtonFilters = {
  eccStatus: true,
  communityType: true,
  experienceLevel: true,
  employmentType: true,
};

function CLCReportsFilterModal({
  show,
  state,
  hide,
  callback,
}: CLCReportsFilterModalProps): JSX.Element {
  const { t } = useTranslation();
  const [reportFilters, setReportFilters] = useState<CLCReportFilterData>(CLCReportFilterDefaultData);
  const [isActive, setIsActive] = useState<AllButtonFilters>(AllButtonDefaultFilters);
  const [currentReportFilters, setCurrentReportFilters] = useState<CLCReportFilterData>(CLCReportFilterDefaultData);
  const [openTo, setOpenTo] = useState<SelectOptionsData[]>([]);
  const [expLevel, setExpLevel] = useState<IExperienceLevel[]>([]);
  const [commType, setCommType] = useState<CommunityTypeData[]>([]);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const employmentTypes = useRecoilValue<EmploymentTypeData[]>(employmentTypeOptionsState);
  const communityTypes = useRecoilValue<CommunityTypeData[]>(communityTypesState);
  const openToOptions = useRecoilValue<SelectOptionsData[]>(openToOptionssState);
  const [isValid, setIsValid] = useState<boolean>(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const {
    handleSubmit,
  } = useForm<CLCReportFilterData>();

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    setCurrentReportFilters(reportFilters);
    callback(true, {
      communityType: reportFilters.communityType,
      experienceLevel: reportFilters.experienceLevel,
      employmentType: reportFilters.employmentType,
      eccStatus: reportFilters.eccStatus,
      state,
      ...data,
    });
    hide();
  };

  const handleClose = () => {
    if (currentReportFilters.communityType.length > 0
      || currentReportFilters.experienceLevel.length > 0
      || currentReportFilters.employmentType.length > 0
      || currentReportFilters.eccStatus.length > 0) {
      setReportFilters(currentReportFilters);
    } else {
      setReportFilters(CLCReportFilterDefaultData);
      setIsActive(AllButtonDefaultFilters);
    }
    hide();
  };

  useEffect(() => {
    setIsValid(
      (reportFilters.communityType.length > 0 || isActive.communityType)
    && (reportFilters.experienceLevel.length > 0 || isActive.experienceLevel)
    && (reportFilters.employmentType.length > 0 || isActive.employmentType)
    && (reportFilters.eccStatus.length > 0 || isActive.eccStatus),
    );
  }, [reportFilters, isActive]);

  useEffect(() => {
    setOpenTo(openToOptions.filter((option: SelectOptionsData) => OPEN_TO_OPTIONS.includes(option.description)));
    setExpLevel(experienceLevels.filter((option: IExperienceLevel) => EXPERIENCE_LEVEL_OPTIONS.includes(option.value)));
    setCommType(communityTypes.filter((option: CommunityTypeData) => COMMUNITY_TYPE_OPTIONS.includes(option.description)));
  }, []);

  const eccStatusButtons = openTo.map((option: SelectOptionsData) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.eccStatus"
      className={`${reportFilters.eccStatus.includes(option.id)
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.eccStatus.includes(option.id)) {
          setReportFilters((p: CLCReportFilterData) => ({
            ...p, eccStatus: reportFilters.eccStatus.filter((item: number) => item !== option.id),
          }));
        } else {
          setReportFilters((p: CLCReportFilterData) => ({
            ...p, eccStatus: [...reportFilters.eccStatus, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          eccStatus: !p,
        }));
      }}
    >
      {option.description === 'CCE' ? t('Candidate') : t(option.description)}
    </Button>
  ));

  const communityTypeButtons = commType.map((option: CommunityTypeData, index: any) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.communityType"
      className={`${reportFilters.communityType.includes(parseInt(option.id, 10))
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.communityType.includes(parseInt(option.id, 10))) {
          setReportFilters((p: any) => ({
            ...p, communityType: reportFilters.communityType.filter((item: any) => item !== option.id),
          }));
        } else {
          setReportFilters((p: any) => ({
            ...p, communityType: [...reportFilters.communityType, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          communityType: !p,
        }));
      }}
    >
      {commType[index].description}
    </Button>
  ));

  const experienceLevelButtons = expLevel.map((option: IExperienceLevel, index: any) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.experienceLevel"
      className={`${reportFilters.experienceLevel.includes(parseInt(option.id, 10))
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.experienceLevel.includes(parseInt(option.id, 10))) {
          setReportFilters((p: any) => ({
            ...p, experienceLevel: reportFilters.experienceLevel.filter((item: any) => item !== option.id),
          }));
        } else {
          setReportFilters((p: any) => ({
            ...p, experienceLevel: [...reportFilters.experienceLevel, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          experienceLevel: !p,
        }));
      }}
    >
      {expLevel[index].value}
    </Button>
  ));

  const employmentTypeButtons = employmentTypes.map((option: EmploymentTypeData, index: any) => (
    <Button
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      name="reportFilters.employmentType"
      className={`${reportFilters.employmentType.includes(parseInt(option.id, 10))
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'
      } clc-report-filter-btn ms-3 mt-2`}
      onClick={() => {
        if (reportFilters.employmentType.includes(parseInt(option.id, 10))) {
          setReportFilters((p: any) => ({
            ...p, employmentType: reportFilters.employmentType.filter((item: any) => item !== option.id),
          }));
        } else {
          setReportFilters((p: any) => ({
            ...p, employmentType: [...reportFilters.employmentType, option.id],
          }));
        }
        setIsActive((p: any) => ({
          ...p,
          employmentType: !p,
        }));
      }}
    >
      {employmentTypes[index].description}
    </Button>
  ));

  // Add the "Select All" button
  const selectAllButton = ((filterType: string) => (
    <Button
      id="select-all"
      key="select-all"
      name="reportFilters.eccStatus"
      className={`clc-report-filter-btn ms-3 mt-2 ${isActive[filterType as keyof AllButtonFilters]
        ? 'clc-report-filter-btn-pressed' : 'clc-report-filter-btn-inactive'}`}
      onClick={() => {
        setReportFilters((p: any) => ({
          ...p,
          [filterType]: [],
        }));
        setIsActive((p: any) => ({
          ...p,
          [filterType]: !p[filterType],
        }));
      }}
    >
      {t('All')}
    </Button>
  ));

  return (
    <Modal
      show={show}
      onHide={hide}
      animation={false}
      className="modalstyle"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Position_Report_Filters')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-auto ">

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div>
                <h6 className="text-center">{t('MDP')}</h6>
                <hr className="mdpdivider" />
                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          {t('EmploymentType')}
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('employmentType')}
                        {employmentTypeButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>

                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          {t('CommunityType')}
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('communityType')}
                        {communityTypeButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div>
                <h6 className="text-center">{t('PDP')}</h6>
                <hr className="mdpdivider" />
                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          {t('ExperienceLevel')}
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('experienceLevel')}
                        {experienceLevelButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>

                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="bodyheader">
                          <div className="checkbox-container">
                            {t('EcclesiasticalStatus')}
                          </div>
                        </Form.Label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {selectAllButton('eccStatus')}
                        {eccStatusButtons}
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>

            </div>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              aria-label="submit"
            />
          </Form>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 text-end">
          <Button
            className="px-3"
            variant="primary"
            onClick={() => { submitRef.current?.click(); }}
            disabled={!isValid}
          >
            {t('Apply_Filters')}
          </Button>
          <Button
            className="mx-2"
            variant="danger"
            onClick={() => {
              callback(false, reportFilters);
              handleClose();
            }}
          >
            {t('PDP.Cancel')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CLCReportsFilterModal;
