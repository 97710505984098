import { Link } from 'react-router-dom';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const BackToDashboard = () => {
  const { t } = useTranslation();

  return (
    <Link to="/" className="dashboard-link">
      <FaLongArrowAltLeft />
      <span>{t('Back_to_Dashboard')}</span>
    </Link>
  );
};

export default BackToDashboard;
