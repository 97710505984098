import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  CommunityTypeData,
} from '../../types/pdp.types';
import {
  communityTypesState,
} from '../../services/state.service';
import { tryParseInt } from '../../utils';

interface CommunityTypeProps {
  communityTypes: number[];
}

const CommunityTypeList: React.FC<{
  options: number[],
  communityTypeOptions: CommunityTypeData[]
}> = ({ options, communityTypeOptions }) => {
  const selectedTypes = [...options].sort((a, b) => a - b)
    .map((optionId) => communityTypeOptions.find((item) => tryParseInt(item.id) === optionId))
    .filter((item): item is CommunityTypeData => item !== undefined);

  return (
    <div>
      {selectedTypes.map((item) => (
        <div key={`community-type-option-${item.id}`}>
          {item.description}
        </div>
      ))}
    </div>
  );
};

const CommunityTypeOptions: React.FC<CommunityTypeProps> = ({ communityTypes }) => {
  const { t } = useTranslation();
  const communityTypeOptions = useRecoilValue<CommunityTypeData[]>(communityTypesState);

  if (!communityTypes.length) {
    return null;
  }

  return (
    <div className="my-4 mx-3">
      <div className="row">
        <div className="col-6">
          <div className="mb-2 reviewtitle">
            {t('Community_Type_Preferences')}
          </div>
          <CommunityTypeList
            options={communityTypes}
            communityTypeOptions={communityTypeOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default CommunityTypeOptions;
